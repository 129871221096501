import {
  Flex,
  Stack,
  Heading,
  Text,
  FormControl,
  Input,
  Button,
  Image,
  Center,
  Box,
} from "@chakra-ui/react";
import { FormInput } from "../../components/form/controls/FormInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TokenService } from "../../service/token.service";
import { IdentityService } from "../../service/identity.service";
import { Loading } from "../../components/shared/Loading";
import { useDispatch } from "react-redux";
import { update } from "../../store/slice/Token/token.slice";

const tokenService = new TokenService();
const identityService = new IdentityService();
export const Login: React.FC<any> = (props: any) => {
  const { key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");

  const onSubmit = async () => {
    setLoading(true);
    await identityService
      .login(password)
      .then((token) => {
        dispatch(update(token));
        navigate("/integrations");
      })
      .catch(() => navigate("/unauthorized"))
      .finally(() => setLoading(false));
  };

  const init = async () => {
    setLoading(true);
    await tokenService
      .verifyKey(key)
      .catch(() => navigate("/unauthorized"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!key) navigate("/unauthorized");
    init();
  }, [key]);

  if (loading) return <Loading loading={true} />;

  return (
    <Flex minH={"100vh"} w="100vw" bg="#F8F9FC">
      <Flex flexDir="row">
      <Box position="relative" h="100vh" w="65vw" overflow="hidden">
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              zIndex: 1
            }}
          >
            <source src="/assets/homepage-animation.mp4" type="video/mp4" />
          </video>
          <Flex
            flexDir="column"
            color="white"
            bg="rgba(0, 0, 0, 0.6)"
            p="16px"
            borderRadius="12px"
            position="absolute"
            w="40vw"
            maxW="600px"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={2}
            style={{ backdropFilter: "blur(5px)" }}
          >
            <Text fontWeight={600} fontSize="24px">
              Experience connected planning with
              <br />
              <Text as="span" color="#7A2551" fontWeight="700">
                {"["}
              </Text>
              SUMLOOKUP
              <Text as="span" color="#7A2551" fontWeight="700">
                {"]"}
              </Text>{" "}
              Lighthouse.
            </Text>
            <Center>
              <Text mt="30px" fontWeight={400} fontSize="18px">
                View, manage, and control your suite of managed services from
                us. We aim to provide visibility in all facets of our service
                offerings to ensure that you are always in control.
              </Text>
            </Center>
          </Flex>
          <Image
            pos="absolute"
            left="10px"
            bottom="10px"
            src="/assets/sidebar/slu-branding.svg"
            alt="Sumlookup Branding"
            width="180px"
            mb={2}
          />
        </Box>
        <Flex color="black" px="5vw" align={"center"} justify={"center"}>
          <form onSubmit={onSubmit}>
            <Stack spacing={4} w={"full"} maxW={"lg"} p={6} my={12}>
              <Text fontWeight="600" fontSize="36px">
                Welcome back.
              </Text>
              <FormInput
                name="password"
                title="Anaplan Token"
                required
                tooltipText="You can find this token in the module created in Anaplan by your SUMLOOKUP consultant."
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <Stack spacing={6}>
                <Button
                  type="submit"
                  bg="#1D1F31"
                  color="white"
                  _hover={{
                    color: "#1D1F31",
                    bg: "white",
                    border: "1px solid #1D1F31",
                  }}
                >
                  Login
                </Button>
              </Stack>

              <Center>
                <Text fontSize="12px">
                  Request your site admin to whitelist your email for access.
                </Text>
              </Center>
            </Stack>
          </form>
          <Box fontSize="12px" pos="absolute" bottom="10px" right="10px">
            © {new Date().getFullYear()} All rights reserved. SUMLOOKUP PTY LTD.
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
