const env =
  process.env.REACT_APP_ENV || process.env.ENVIRONMENT || process.env.NODE_ENV;
const isProd =
  env && (env.toLowerCase() === "prod" || env.toLowerCase() === "production");

let apiUrl = "http://localhost:9100";
let instance = "local";

if (isProd) {
  apiUrl = "https://api.partnerslife.sumlookup.com";
  instance = "prod";
}

const config = {
  apiUrl,
  instance,
};

export default config;
