export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (phone: string) => {
  return String(phone).match(
    /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/
  );
};

export const validateSocialsUrl = (url: string) => {
  if (!url?.length) return true;

  return String(url.toLowerCase()).match(
    /(?:(?:http|https):\/\/)?(?:www.)?linkedin.com\b.*|(?:(?:http|https):\/\/)?(?:www.)?twitter.com\b.*|(?:(?:http|https):\/\/)?(?:www.)?instagram.com\b.*|(?:(?:http|https):\/\/)?(?:www.)?facebook.com\b.*$/
  );
};

export const validateYoutubeUrl = (url: string) => {
  return String(url.toLowerCase()).match(
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
  );
};

export const validateUrl = (url: string) => {
  if (!url?.length) return true;

  if (url.includes(" ")) return null;

  const res = String(url).match(
    /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~#?&\/=]*|)$/
  );

  return res;
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
