import {
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { formatDate } from "../../utils/date.util";
import { ArrowRightIcon, RepeatIcon } from "@chakra-ui/icons";
import { IntegrationService } from "../../service/integration.service";
import { IntegrationItem } from "../../models/integration-item.model";
import { StatusTag } from "../../components/shared/StatusTag";
import { Loading } from "../../components/shared/Loading";
import { errorToast, successToast } from "../../constants/toast.constants";
import { useNavigate } from "react-router-dom";
import { WorkflowTriggerModal } from "../../components/modal/WorkflowTriggerModal";
import { delay } from "../../utils/form.util";

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
};

const integrationService = new IntegrationService();

export const Integrations: React.FC<any> = (props: any) => {
  const [integrations, setIntegrations] = useState([] as IntegrationItem[]);
  const [loading, setLoading] = useState(true);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [activeId, setActiveId] = useState("");

  const toast = useToast();
  const navigate = useNavigate();

  const init = async () => {
    setLoading(true);
    await setIntegrationData().finally(() => {
      setLoading(false);
      setWarningModalOpen(false);
    });
  };

  const setIntegrationData = async () => {
    const integrations = await integrationService.getWorkflowWithRuns();
    setIntegrations(integrations);
  };

  const triggerWorkflow = async () => {
    setLoading(true);
    setWarningModalOpen(false);
    await integrationService
      .triggerWorkflow(activeId)
      .then(async () => {
        toast(successToast("Integration started."));
        await delay(2500).then(async () => {
          await init();
        });
      })
      .catch(() => {
        toast(errorToast("Integration failed to start."));
      })
      .finally(() => setLoading(false));
  };

  const onTriggerWorkflowClick = async (workflowId: string) => {
    setWarningModalOpen(true);
    setActiveId(workflowId);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />
      <Flex
        flexDirection="row"
        px="48px"
        pt="24px"
        justifyContent="space-between"
      >
        <Text fontSize="18px" fontWeight="bold">
          Active Integrations
        </Text>
        <RepeatIcon
          cursor="pointer"
          w="36px"
          h="36px"
          _hover={{ color: "#29ab02" }}
          onClick={init}
        />
      </Flex>
      <WorkflowTriggerModal
        isOpen={warningModalOpen}
        onClose={() => {
          setWarningModalOpen(false);
          setActiveId("");
        }}
        onConfirm={triggerWorkflow}
      />
      <BaseContainer styles={baseContainerStyles}>
        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Last Run</Th>
                <Th>Last Run Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* these are workflows */}
              {integrations?.map((integration: IntegrationItem, i: number) => (
                <Tr key={i}>
                  <Td
                    fontWeight={600}
                    _hover={{ color: "#29ab02", cursor: "pointer" }}
                    onClick={() => navigate(`/history/${integration.id}`)}
                  >
                    {integration.name}
                  </Td>
                  <Td>{integration.description}</Td>
                  <Td>{formatDate(integration.lastRunDate)}</Td>
                  <Td>
                    {!!integration.conclusion && (
                      <StatusTag
                      type={integration.conclusion as any}
                      style={{ width: "120px" }}
                      >
                      {integration.conclusion}
                    </StatusTag>
                    )}
                  </Td>
                  <Td>
                    <Flex flexDirection="row" gap="16px">
                      {!integration.lastRunStatus || integration.lastRunStatus === "completed" ? (
                        <Image
                          src="/assets/sidebar/play.png"
                          cursor="pointer"
                          w="36px"
                          h="36px"
                          _hover={{ color: "#29ab02" }}
                          onClick={() => onTriggerWorkflowClick(integration.id)}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </BaseContainer>
    </Layout>
  );
};
