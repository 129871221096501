import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { formatDate } from "../../utils/date.util";
import { IntegrationService } from "../../service/integration.service";
import { StatusTag } from "../../components/shared/StatusTag";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/shared/Loading";
import { RepeatIcon } from "@chakra-ui/icons";

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
  pb: "75px",
};

const integrationService = new IntegrationService();

export const History: React.FC<any> = (props: any) => {
  const { id } = useParams();
  const [runs, setRuns] = useState([] as any[]);
  const [loading, setLoading] = useState(true);

  const init = async () => {
    setLoading(true);
    await setWorkflowData().finally(() => {
      setLoading(false);
    });
  };

  const setWorkflowData = async () => {
    await integrationService.getWorkflowRunsHistory(id).then((history) => {
      setRuns(history);
    });
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <Layout>
      <Loading loading={loading} />
      <Flex
        flexDirection="row"
        px="48px"
        pt="24px"
        justifyContent="space-between"
      >
        <Text fontSize="18px" fontWeight="bold">
          Run History
        </Text>
        <RepeatIcon
          cursor="pointer"
          w="36px"
          h="36px"
          _hover={{ color: "#29ab02" }}
          onClick={init}
        />
      </Flex>
      <BaseContainer styles={baseContainerStyles}>
        <Accordion allowToggle>
          {runs?.map((run: any, i: number) => (
            <AccordionItem key={run.id}>
              <h2>
                <AccordionButton>
                  <Box py="14px" as="span" flex="1" textAlign="left">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      w="80%"
                    >
                      <Flex flexDirection="row">
                        {run.name}
                        {!run.conclusion ? <Spinner ml="12px" /> : <></>}
                      </Flex>
                      <Box> {formatDate(run.lastRunDate)}</Box>
                      <Box>
                        {" "}
                        <StatusTag
                          type={run.conclusion}
                          style={{ width: "120px" }}
                        >
                          {run.conclusion}
                        </StatusTag>
                      </Box>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                -
                <br />
                {!run.conclusion ? (
                  `Currently running...`
                ) : (
                  <Flex flexDirection="column" gap="16px">
                    <Text>
                      Completed with status {run.conclusion} in {run.attempt}{" "}
                      attempt(s).
                    </Text>
                    <Text>Duration: {run.duration}s</Text>
                  </Flex>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        <Box fontSize="14px" position="absolute" p="16px" bottom="0">
          Displaying top 30 results.
        </Box>
      </BaseContainer>
    </Layout>
  );
};
