export const successToast = (description?: string, duration?: number) => {
  return {
    status: "success",
    description: description ?? "Success",
    duration: duration ?? 5000,
    isClosable: true,
    position: "bottom-right",
  } as any;
};

export const errorToast = (description?: string, duration?: number) => {
  return {
    status: "error",
    description: description ?? "Error",
    duration: duration ?? 5000,
    isClosable: true,
  } as any;
};
