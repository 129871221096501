import React from "react";
import styles from "../../styles/Home.module.css";
import { Box, Text } from "@chakra-ui/react";
import { Sidebar } from "./Sidebar";

const Layout: React.FC<any> = (props: any) => {
  const { title, metaAttrs, children } = props;

  return (
    <>
      <Sidebar />
      <Box pl={{ base: 0, lg: "280px" }}>
        <main id="main" className={styles.main}>
          {children}
        </main>
      </Box>
    </>
  );
};

export default Layout;
