import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Login } from "./views/auth/Login";
import { Integrations } from "./views/app/Integrations";
import { History } from "./views/app/History";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store, persistor, RootState } from "./store";
import { Loading } from "./components/shared/Loading";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider>
        <PersistGate loading={<Loading loading={true} />} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <>
                    Not Allowed Here. Go to the system provider to retrieve a
                    new link.
                  </>
                }
              />
              <Route
                path="/unauthorized"
                element={
                  <>
                    Not Allowed Here - Go to the system provider to retrieve a
                    new link.
                  </>
                }
              />
              <Route
                path="/session-end"
                element={<>Please close this tab.</>}
              />
              <Route path="/auth/login/:key" element={<Login />} />
              <Route path="/dashboard" element={<AuthenticatedRoute />}>
                <Route path="/dashboard" element={<AuthenticatedRoute />} />
              </Route>
              <Route path="/integrations" element={<AuthenticatedRoute />}>
                <Route path="/integrations" element={<Integrations />} />
              </Route>
              <Route path="/history/:id" element={<AuthenticatedRoute />}>
                <Route path="/history/:id" element={<History />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </ChakraProvider>
    </Provider>
  );
}

const AuthenticatedRoute = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.token.accessToken);

  // Get from store
  const authenticated = !!token?.length;

  // If authorized, return an outlet passed from parent
  return authenticated ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default App;
