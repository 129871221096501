import { Box, Button, Flex, Link, Text, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  LinkIcon,
  LockIcon,
  RepeatClockIcon,
} from "@chakra-ui/icons";
import { Workflow } from "../../models/github/workflow.model";
import { IntegrationService } from "../../service/integration.service";
import { useDispatch } from "react-redux";
import { update } from "../../store/slice/Token/token.slice";

const integrationService = new IntegrationService();

export const Sidebar: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [workflows, setWorkflows] = useState([] as Workflow[]);
  const [historyExpanded, setHistoryExpanded] = useState(false);

  const logout = () => {
    dispatch(update({ accessToken: "" }));
    navigate("/session-end");
  };

  const init = async () => {
    const workflows = await integrationService.getWorkflows();
    setWorkflows(workflows);

    for (let workflow of workflows) {
      if (window.location.pathname.startsWith(`/history/${workflow.id}`)) {
        setHistoryExpanded(true);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Box
      maxW="280px"
      minH="100%"
      display={{ base: "none", lg: "block" }}
      position="fixed"
    >
      <Flex
        minH="100vh"
        bg="#1D1F31"
        w="280px"
        maxW="280px"
        p="24px 12px 12px 12px"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <a onClick={() => navigate("/jobs")}>
            <Image
              src="/assets/logo-pl.png"
              alt="Partners Life Logo"
              width="180px"
              height="40px"
              mb={6}
              cursor="pointer"
            />
          </a>

          <LinkStyled
            active={window.location.pathname.startsWith(`/integrations`)}
            onClick={() => navigate(`/integrations`)}
          >
            <Flex flexDirection="row" gap={2} alignItems={"center"}>
              <Icon link={"integrations"} />
              <Text fontSize={"15px"}>Integrations</Text>
            </Flex>
          </LinkStyled>

          <LinkStyled
            active={historyExpanded}
            onClick={() => setHistoryExpanded(!historyExpanded)}
          >
            <Flex flexDirection="row" justifyContent="space-between">
              <Flex flexDirection="row" gap={2} alignItems={"center"}>
                <Icon link={"history"} />
                <Text fontSize={"15px"}>History</Text>
              </Flex>

              <Link as={ChevronDownIcon} h="26px" w="26px" />
            </Flex>
          </LinkStyled>

          <Box pl="13px" hidden={!historyExpanded}>
            {workflows.map((workflow, i) => {
              return (
                <SublinkStyled
                  active={window.location.pathname.startsWith(
                    `/history/${workflow.id}`
                  )}
                  key={i}
                  onClick={() => navigate(`/history/${workflow.id}`)}
                >
                  <Flex flexDirection="row" gap={2} alignItems={"center"}>
                    <Link as={LinkIcon} />
                    <Text>{workflow.name}</Text>
                  </Flex>
                </SublinkStyled>
              );
            })}
          </Box>
        </Box>

        <Box ml="6px">
          <Box ml="6px">
            <LinkStyled onClick={logout}>
              <Flex flexDirection="row" gap={2} alignItems={"center"}>
                <Link as={LockIcon} />
                <Text fontSize={"15px"}>End Session</Text>
              </Flex>
            </LinkStyled>
          </Box>
          <Image
            src="/assets/sidebar/slu-branding.svg"
            alt="Sumlookup Branding"
            width="160px"
            mb={6}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection={"row"}
      alignItems="center"
      bg={active ? "#333448" : "#1D1F31"}
      mb={{
        lg: "12px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      _hover={{
        bg: "#333448",
      }}
      py="12px"
      color="white"
      borderRadius="8px"
      w="100%"
    >
      {children}
    </Button>
  );
};

const SublinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection={"row"}
      alignItems="center"
      fontSize="14px"
      bg={active ? "#333448" : "#1D1F31"}
      mb={{
        lg: "12px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      _hover={{
        bg: "#333448",
      }}
      py="12px"
      color="white"
      borderRadius="8px"
      w="100%"
    >
      {children}
    </Button>
  );
};

const Icon: React.FC<any> = (props: any) => {
  const { link } = props;

  if (link === "integrations") return <Link as={LinkIcon} />;
  if (link === "history") return <Link as={RepeatClockIcon} />;
};
